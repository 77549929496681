export const AdditionalLink = () => import('../../components/storyblok/AdditionalLink.vue' /* webpackChunkName: "components/additional-link" */).then(c => wrapFunctional(c.default || c))
export const AdditionalLinks = () => import('../../components/storyblok/AdditionalLinks.vue' /* webpackChunkName: "components/additional-links" */).then(c => wrapFunctional(c.default || c))
export const AdditionalLinkv2 = () => import('../../components/storyblok/AdditionalLinkv2.vue' /* webpackChunkName: "components/additional-linkv2" */).then(c => wrapFunctional(c.default || c))
export const AdviceBlock = () => import('../../components/storyblok/AdviceBlock.vue' /* webpackChunkName: "components/advice-block" */).then(c => wrapFunctional(c.default || c))
export const AutorizzataLabel = () => import('../../components/storyblok/AutorizzataLabel.vue' /* webpackChunkName: "components/autorizzata-label" */).then(c => wrapFunctional(c.default || c))
export const Bar = () => import('../../components/storyblok/Bar.vue' /* webpackChunkName: "components/bar" */).then(c => wrapFunctional(c.default || c))
export const BottomFooter = () => import('../../components/storyblok/BottomFooter.vue' /* webpackChunkName: "components/bottom-footer" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/storyblok/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/storyblok/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CardWithHeading = () => import('../../components/storyblok/CardWithHeading.vue' /* webpackChunkName: "components/card-with-heading" */).then(c => wrapFunctional(c.default || c))
export const Certified = () => import('../../components/storyblok/Certified.vue' /* webpackChunkName: "components/certified" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCheckbox = () => import('../../components/storyblok/CheckoutCheckbox.vue' /* webpackChunkName: "components/checkout-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CmsTitle = () => import('../../components/storyblok/CmsTitle.vue' /* webpackChunkName: "components/cms-title" */).then(c => wrapFunctional(c.default || c))
export const Column = () => import('../../components/storyblok/Column.vue' /* webpackChunkName: "components/column" */).then(c => wrapFunctional(c.default || c))
export const Columns = () => import('../../components/storyblok/Columns.vue' /* webpackChunkName: "components/columns" */).then(c => wrapFunctional(c.default || c))
export const Content = () => import('../../components/storyblok/Content.vue' /* webpackChunkName: "components/content" */).then(c => wrapFunctional(c.default || c))
export const Copyright = () => import('../../components/storyblok/Copyright.vue' /* webpackChunkName: "components/copyright" */).then(c => wrapFunctional(c.default || c))
export const CustomerCare = () => import('../../components/storyblok/CustomerCare.vue' /* webpackChunkName: "components/customer-care" */).then(c => wrapFunctional(c.default || c))
export const FooterPartners = () => import('../../components/storyblok/FooterPartners.vue' /* webpackChunkName: "components/footer-partners" */).then(c => wrapFunctional(c.default || c))
export const FooterTop = () => import('../../components/storyblok/FooterTop.vue' /* webpackChunkName: "components/footer-top" */).then(c => wrapFunctional(c.default || c))
export const Grid = () => import('../../components/storyblok/Grid.vue' /* webpackChunkName: "components/grid" */).then(c => wrapFunctional(c.default || c))
export const HeroImage = () => import('../../components/storyblok/HeroImage.vue' /* webpackChunkName: "components/hero-image" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/storyblok/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const ImageLink = () => import('../../components/storyblok/ImageLink.vue' /* webpackChunkName: "components/image-link" */).then(c => wrapFunctional(c.default || c))
export const ImageSlider = () => import('../../components/storyblok/ImageSlider.vue' /* webpackChunkName: "components/image-slider" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/storyblok/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const Links = () => import('../../components/storyblok/Links.vue' /* webpackChunkName: "components/links" */).then(c => wrapFunctional(c.default || c))
export const MenuLinks = () => import('../../components/storyblok/MenuLinks.vue' /* webpackChunkName: "components/menu-links" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSubscribe = () => import('../../components/storyblok/NewsletterSubscribe.vue' /* webpackChunkName: "components/newsletter-subscribe" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSubscribeModal = () => import('../../components/storyblok/NewsletterSubscribeModal.vue' /* webpackChunkName: "components/newsletter-subscribe-modal" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/storyblok/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const Partner = () => import('../../components/storyblok/Partner.vue' /* webpackChunkName: "components/partner" */).then(c => wrapFunctional(c.default || c))
export const Partners = () => import('../../components/storyblok/Partners.vue' /* webpackChunkName: "components/partners" */).then(c => wrapFunctional(c.default || c))
export const Post = () => import('../../components/storyblok/Post.vue' /* webpackChunkName: "components/post" */).then(c => wrapFunctional(c.default || c))
export const RowCard = () => import('../../components/storyblok/RowCard.vue' /* webpackChunkName: "components/row-card" */).then(c => wrapFunctional(c.default || c))
export const RowCards = () => import('../../components/storyblok/RowCards.vue' /* webpackChunkName: "components/row-cards" */).then(c => wrapFunctional(c.default || c))
export const RowImage = () => import('../../components/storyblok/RowImage.vue' /* webpackChunkName: "components/row-image" */).then(c => wrapFunctional(c.default || c))
export const RowText = () => import('../../components/storyblok/RowText.vue' /* webpackChunkName: "components/row-text" */).then(c => wrapFunctional(c.default || c))
export const RowTextImage = () => import('../../components/storyblok/RowTextImage.vue' /* webpackChunkName: "components/row-text-image" */).then(c => wrapFunctional(c.default || c))
export const ShelveCategories = () => import('../../components/storyblok/ShelveCategories.vue' /* webpackChunkName: "components/shelve-categories" */).then(c => wrapFunctional(c.default || c))
export const SimplePage = () => import('../../components/storyblok/SimplePage.vue' /* webpackChunkName: "components/simple-page" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/storyblok/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const Store = () => import('../../components/storyblok/Store.vue' /* webpackChunkName: "components/store" */).then(c => wrapFunctional(c.default || c))
export const StoreLocator = () => import('../../components/storyblok/StoreLocator.vue' /* webpackChunkName: "components/store-locator" */).then(c => wrapFunctional(c.default || c))
export const TopFooterColumn = () => import('../../components/storyblok/TopFooterColumn.vue' /* webpackChunkName: "components/top-footer-column" */).then(c => wrapFunctional(c.default || c))
export const VCHeader = () => import('../../components/storyblok/VCHeader.vue' /* webpackChunkName: "components/v-c-header" */).then(c => wrapFunctional(c.default || c))
export const Verified = () => import('../../components/storyblok/Verified.vue' /* webpackChunkName: "components/verified" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
